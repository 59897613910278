<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Product review for {{supplierName}} Supplier Integration
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            ref="grid"
            :selected-rows.sync="selectedRows"
        ></data-table>
        <div class="flex justify-content-between mt-2">
            <mercur-button class="btn btn-raised text-uppercase" :to="getAttributeMappingRoute()">Back to attribute mapping</mercur-button>
            <div class="flex">
                <p><strong>{{selectedRows.length}}</strong> products selected</p>
                <mercur-button class="btn btn-raised btn-yellow text-uppercase ml-2" @click="openProductGenerateDialog" :disabled="selectedRows.length === 0">Generate</mercur-button>
            </div>
        </div>
        <mercur-dialog :is-open.sync="generateProductsConfirmActive">
            <div slot="header"><h2 class="font-weight-normal">Select facilities to generate the products</h2></div>
            <div slot="default" class="full-height-layout fill">
                <div>
                    <pretty-select
                        v-if="supplierFacilities"
                        placeholder="Facility"
                        label="locationName"
                        :options="supplierFacilities"
                        v-model="selectedFacilities"
                        :disabled="loading"
                        multiple
                        required
                    ></pretty-select>
                </div>
            </div>
            <div slot="footer">
                <mercur-button class="btn text-uppercase" @click="generateSelectedProducts" :disabled="loading || selectedFacilities.length === 0">Submit</mercur-button>
            </div>
            <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import PrettySelect from '../../components/utils/PrettySelect'

export default {
    name: 'SupplierIntegrationProductReview',
    components: { PrettySelect, DataTable, GridHeader },
    props: ['supplierIntegrationConfig', 'supplierName', 'supplierIntegrationSupplierId'],
    data () {
        return {
            selectedRows: [],
            generateProductsConfirmActive: false,
            supplierFacilities: null,
            selectedFacilities: [],
            loading: false,
            options: {
                columns: {
                    'Product name': {
                        field: 'supplierIntegrationProductName',
                        sortable: true,
                        cellRenderer: 'agGroupCellRenderer',
                    },
                },
                isSelectable: true,
                enableRangeSelection: true,
                quickSearchColumns: ['supplierIntegrationProductName'],
                masterDetail: true,
                getRowHeight: params => {
                    if (params.node.detail) {
                        return Math.min(params.data.attributes.length * CONFIG.GRIDS.DEFAULT_ROW_HEIGHT, CONFIG.GRIDS.MAX_DETAIl_ROW_HEIGHT) + 62
                    }

                    return CONFIG.GRIDS.DEFAULT_ROW_HEIGHT
                },
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                field: 'integrationAttributeName',
                                headerName: 'Integration attribute name',
                            },
                            {
                                field: 'integrationAttributeOption',
                                headerName: 'Integration attribute option',
                            },
                            {
                                field: 'mappedAttributes',
                                headerName: 'Mapping',
                                cellClass: 'ag-grid__cell--multi-line',
                                autoHeight: true,
                                valueFormatter: ({ value }) => {
                                    if (!value) {
                                        return ''
                                    }
                                    return value.flatMap(({ attributeName, attributeOption }) => {
                                        return `${attributeName}: ${attributeOption}`
                                    }).join(`\n`)
                                },
                            },
                        ],
                    },
                    getDetailRowData: params => {
                        params.successCallback(params.data.attributes || params.data.mappingAttributes)
                        setTimeout(() => {
                            params.node.detailGridInfo.api.sizeColumnsToFit()
                        }, 10)
                    },
                },
            },
            url: CONFIG.API.ROUTES.SUPPLIER_INTEGRATIONS.PRODUCTS.OVERVIEW.replace('{supplierIntegrationConfigId}', this.$route.params.supplierIntegrationConfigId),
            filters: {
                search: '',
            },
        }
    },
    computed: {
        selectedProductNames () {
            return this.selectedRows.flatMap(({ supplierIntegrationProductName }) => supplierIntegrationProductName)
        },
    },
    methods: {
        openProductGenerateDialog () {
            this.generateProductsConfirmActive = true
        },

        generateSelectedProducts () {
            this.loading = true
            const supplierIntegrationProductIds = this.selectedRows.flatMap(data => data.supplierIntegrationProductId)
            this.addJob(supplierIntegrationProductIds)
            const url = CONFIG.API.ROUTES.SUPPLIER_INTEGRATIONS.PRODUCTS.GENERATE.replace('{supplierIntegrationConfigId}', this.$route.params.supplierIntegrationConfigId)
            const payload = {
                supplierIntegrationProductIds,
                selectedFacilities: this.selectedFacilities,
            }
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Product generation is scheduled. Please check the suppliers product list for the status`,
                })
                this.generateProductsConfirmActive = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Scheduling products for generation. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(supplierIntegrationProductIds)
            })
        },

        getAttributeMappingRoute () {
            return {
                name: 'SupplierIntegrationDetail',
                params: {
                    ...this.$route.params,
                },
            }
        },
    },
    created () {
        this.supplierIntegrationConfig.then(({ supplierId }) => {
            this.$store.dispatch('suppliers/fetchSupplierLocations', supplierId).then(data => {
                this.supplierFacilities = data.filter(location => location.locationType === 'FACILITY').map(facility => {
                    return {
                        ...facility,
                    }
                })
                this.selectedFacilities.push(this.supplierFacilities[0])
            })
        })
    },
}
</script>
